@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
}
html,
body,
#root,
#root > div {
  height: 100%;
}

body {
  margin: 0px;
  font-size: 14px !important;
}

.container {
  height: 100%;
  background-color: #f6f5f7;
}

.pageTop {
  /* margin: 10px; */
  min-height: 300px;
  padding: 32px;
  background-color: #fff;
}
.pageBottom {
  padding: 32px;
  background-color: #f6f5f7;
}
.table-header {
  padding-left: 10px;
}

thead th:first-child {
  border-radius: 8px 0px 0px 0px;
  background-color: #d6eaee;
}
thead th {
  background-color: #d6eaee;
}
thead th:last-child {
  border-radius: 0px 8px 0px 0px;
  background-color: #d6eaee;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  margin-top: 12px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}
.customCursor {
  cursor: pointer;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
  background-color: #e4002b;
}

.flexBreak {
  display: flex;
  flex-wrap: wrap;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}
input {
  width: 150px;
}

.topContainer {
  width: 400px;
  /* padding: 10px 0 20px 0; */
}

.topInputContainer {
  width: 200px;
  padding: 0;
  margin-bottom: 24px;
}
.TargetBtnContainer {
  width: 200px;
  padding: 10px 0 0 0;
}

th {
  color: #4f6b72;
  /* border-right: 1px solid #c1dad7;
  border-bottom: 1px solid #c1dad7;
  border-top: 1px solid #c1dad7; */
  /* letter-spacing: 2px; */
  /* text-transform: uppercase; */
  text-align: left;
  /* padding: 6px 6px 6px 12px; */
}

th.nobg {
  border-top: 0;
  border-left: 0;
  border-right: 1px solid #c1dad7;
  background: none;
}
table {
  table-layout: fixed;
  width: 100%;
}

table tbody tr:not(:first-of-type) {
  border-top: 1px solid #e5e3e8;
}

td {
  border-right: 1px solid #c1dad7;
  border-bottom: 1px solid #c1dad7;
  background: #fff;
  padding: 6px 6px 6px 12px;
  color: #3d3b40;
  overflow-wrap: break-word;
}

td.alt {
  background: #f5fafa;
  color: #797268;
}

th.spec {
  border-left: 1px solid #c1dad7;
  border-top: 0;
  background: #fff
    url(https://reamediatools.com/SAS/quick_avails/images/bullet1.gif) no-repeat;
}

th.specalt {
  border-left: 1px solid #c1dad7;
  border-top: 0;
  background: #f5fafa
    url(https://reamediatools.com/SAS/quick_avails/images/bullet2.gif) no-repeat;
  color: #797268;
}

td.resultTable {
  padding: 4px 4px 12px 4px;
  vertical-align: top;
}

td.resultTable .buttonCell {
  display: block;
  margin-left: 5px;
}

.targetOpen {
  cursor: pointer;
}

.targetOpen:hover {
  text-decoration: underline;
}

#product_suggest,
#targeting_suggest {
  display: none;
}

ul {
  /* border: 1px solid #eaeaea; */
  /* position: absolute; */
  z-index: 9;
  /* background: #f3f3f3; */
  list-style: none;
}
ul li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #fff; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

ul li {
  padding: 10px;
}

ul li:hover {
  /* background: #eaeaea; */
}

.batchTable td {
  padding: 5px;
}

#batch_header {
  background: #cae8ea
    url('https://reamediatools.com/SAS/quick_avails/images/bg_header.jpg')
    no-repeat scroll 0 0;
  border: 1px solid #c1dad7;
  color: #4f6b72;
  letter-spacing: 2px;
  padding: 6px 6px 6px 12px;
  text-align: left;
  text-transform: uppercase;
  width: 500px;
}

#batch_header span {
  cursor: pointer;
  font-size: 8px;
  letter-spacing: 1px;
}

#batch_body {
  width: 500px;
  /* background-color: white; */
  padding: 0 6px 6px 0px;
  margin-top: 20px;
}

#list_of_subs {
  resize: none;
  border: 1px solid #c1dad7;
  overflow: auto;
  padding: 5px;
  margin-bottom: 5px;
  width: 525px;
  height: 136px;
}

note {
  display: block;
  font-size: 12px;
  margin: 15px 0 0 5px;
}

#lightbox {
  position: fixed; /* keeps the lightbox window in the current viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  display: none;
}

#lb_content {
  box-shadow: 0 0 25px #111;
  -webkit-box-shadow: 0 0 25px #111;
  -moz-box-shadow: 0 0 25px #111;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  /* height: 50%; */
}

.targeting_table td {
  border: 1px solid #c1dad7;
  min-width: 100px;
}

.textwrapstyle {
  background-color: #e6ecec;
  color: black;
  border-radius: 20px;
  width: auto;
  height: auto;
  padding: 5px 7px 5px 7px;
  margin: 2px;
  float: left;
}
.pill-link {
  cursor: pointer;
}
.pill-link:hover {
  text-decoration: underline;
}

strong {
  font-weight: bold !important;
}

#techOps-logo {
  margin-top: 82px !important;
  text-align: left;
}

.target-icon {
  font-weight: 700;
  color: #fff;
  border-radius: 3px;
  padding: 3px 7px;
  margin-right: 5px;
}

.target-icon.suburb {
  background-color: #00008b;
}

.target-icon.suburb:after {
  content: 'S';
}

.target-icon.target:after {
  content: 'T';
}

.target-icon.target {
  background-color: #8b0000;
}

.target-icon.precinct {
  background-color: #006400;
}

.target-icon.proptype {
  background-color: #8b008b;
}

.target-icon.proptype:after {
  content: 'P';
}

.calendar-class {
  margin-left: 48px;
}

.tooltip-content {
  max-width: 24rem !important;
}
